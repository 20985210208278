<template>
  <div class="d-flex justify-content-center m-5 p-5">
    <b-spinner variant="primary" class="sp-loader" label="Spinning" />
  </div>
</template>

<script>
export default {
  name: 'SpLoader',
};
</script>

<style lang="scss" scoped>
  .sp-loader{
    width: 3rem;
    height: 3rem;
  }
</style>
