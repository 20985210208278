<template>
  <div class="container">
    <!-- Loader -->
    <sp-loader v-if="loading" />

    <!-- Title -->
    <div v-if="!loading" class="row justify-content-center p-5">
      <div class="col-sm-10 text-center">
        <h2>Pick a path to follow</h2>
      </div>
    </div>
    <!-- Learning path list -->
    <div v-if="!loading" class="row justify-content-center">
      <div class="col-sm-10">
        <ul class="list-group-shadow list-group">
          <router-link v-for="lpath in learningPaths" :key="lpath.id" :to="{ name: 'path', params: { pathslug: lpath.slug } }"
                       append class="list-group-item list-group-item-action mt-3 mb-3">
            <div class="row media p-3">
              <div class="col-12 col-lg-3 text-center align-self-center mb-2">
                <b-img-lazy class="img-fluid mr-3 course-thumbnail" :src="SPENV.S3_URL + '/images/paths-thumbs/path-' + lpath.path_id + '.jpg'" alt="Learning Track thumbnail"
                            onerror="this.onerror=null;this.src='/static/images/courses/placeholder-transparent.png'" />
              </div>
              <div class="col media-body m-2">
                <h4 class="font-weight-semibold mt-0 mb-1">
                  {{ lpath.display_title }}
                </h4>
                <div v-if="lpath.description" v-line-clamp:24="'3'" class="text-muted mb-2 preserve-word">
                  <div>
                    {{ lpath.description | striphtml }}
                  </div>
                </div>
                <div class="align-text-bottom">
                  <span class="badge" :class="[lpath.progress === 100? 'badge-success': 'badge-light text-muted']">
                    {{ progress(lpath.progress) }}% Done
                  </span>
                  <span class="badge badge-light text-muted">
                    {{ duration(lpath.duration) }}
                  </span>
                </div>
              </div>
            </div>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import bodyClass from '@/utils/mixins/BodyClass';
import SpLoader from '@/components/SPLoader';

export default {
  name: 'LearningPathList',
  components: {
    'sp-loader': SpLoader
  },
  mixins: [bodyClass],
  data () {
    return {
      bodyClass: 'body-offwhite',
      loading: false
    };
  },
  computed: {
    learningPaths () {
      return this.$store.getters['paths/getPathList'];
    },
    learningPathsLoaded () {
      return this.$store.getters['paths/getPathsLoaded'];
    }
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * Get initial path list with name, description, duration and user progress
     */
    init () {
      this.loading = true;
      let pathlist = this.$store.dispatch('paths/loadPathList');
      let pathProgress = this.$store.dispatch('paths/loadPathListProgress');
      Promise.all([pathlist, pathProgress]).then(
        (response) => {
          this.$store.commit('paths/setPathsLoaded', true);
          this.$store.commit('paths/setpathList', response);
          this.loading = false;
        });
    },
    /**
     * Get hours and minutes of a duration
     * @param {int} seconds course duration
     * @returns {string}
     */
    duration (seconds) {
      let hours = parseInt(seconds / 3600);
      let secondsPastHour = seconds % 3600;
      let minutes = parseInt(secondsPastHour / 60);
      return hours + 'h ' + minutes + 'm';
    },
    /**
     * Format progress percent
     * @param percent
     */
    progress (percent) {
      percent = percent || 0;
      return parseFloat(Math.round(percent)).toFixed(0);
    }
  }
};
</script>

<style scoped></style>
